import React from 'react';
import styled from 'styled-components';
import { Chips } from '@naf/chips';
import { useHistory } from 'react-router-dom';
import useSelector from '../../redux/typedHooks';
import { SiteStructureType } from '../../../../types/siteStructureType';

interface Props {
  chips: string[];
  currentCategory?: SiteStructureType;
}

const SubCategoryChips = ({ chips, currentCategory }: Props) => {
  const categoriesState = useSelector((state) => state.sitestructure);
  const history = useHistory();

  if (!categoriesState) return null;

  return (
    <ChipsWrapper>
      {chips.map((chip: string) => {
        const chipCategory = categoriesState?.mappedCategories && categoriesState?.mappedCategories[chip]?.data;
        if (!chipCategory) return null;

        let chipUrl = chipCategory.url;
        let chipName = chipCategory.name;

        // If the chip is a global tag, use current category parent (if it is a subcategory), or currentcategory itself as url, to get correct filter (ie elbil/nyheter)
        // If the chip matches the current category then use category url
        if (chipCategory.isGlobalTag && currentCategory?.url !== chipCategory.url) {
          chipUrl = currentCategory?.parent
            ? `/${currentCategory.parentSlug}/${chipCategory.slug}`
            : `/${currentCategory?.slug}/${chipCategory.slug}`;
          chipName = chipCategory.name;
        }

        const selected = currentCategory && chipCategory && currentCategory.slug === chipCategory.slug;

        if (!chipCategory) return null;

        // get article count
        // const articleCount = 85; // todo - should use /articles?categories=(mappedCategory.slug)&take=0 to get total in category
        return (
          <Chips
            key={`chip-${chip}`}
            // text={`${chipName} (${articleCount})`}
            text={`${chipName}`}
            variant={selected ? 'signature' : 'outline'}
            onClick={() => {
              if (chipUrl) history.push(chipUrl);
            }}
          />
        );
      })}
    </ChipsWrapper>
  );
};

export default SubCategoryChips;

const ChipsWrapper = styled.div`
  margin-top: -3px;
  margin-bottom: 4px;
`;
